import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogUrl = "https://selbysellssd.com/va-home-loan-advantages-requirements";

  return (
    <LayoutWrapper
      title="VA Home Loan Advantages & Requirements | The Selby Team"
      desc="Learn how a VA home Loan can offer many benefits for veterans & military personnel, the eligibility requirements, & how to take advantage of these benefits!"
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              VA Home Loan Advantages & Requirements
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By The Selby Team
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">May 20, 2023</p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={blogUrl} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_VA Home Loan Advantages & Requirements.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                VA home loans differ from traditional mortgage loans in several key ways. Lenders
                make special conditions for those who have faithfully served the United States and
                their spouses. Qualifying borrowers can receive benefits unique to military service
                people.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Let’s take a closer look at some notable VA home loan advantages, and the
                requirements you’ll have to meet.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Advantages of a VA Home Loan
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The potential benefits of a VA home loan are substantial. They can offer relief for
                new and current service members struggling to care for their families.
              </p>
              <h2 className="font-IbarraRealNova text-d-6lg text-d text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Zero Down Payment
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The primary advantage military personnel most cite for VA home loans is the upfront
                cost, or, rather, the lack of it. Other mortgage programs involve a down payment
                between 2% and 5% of the overall price. With VA home loans, there’s no down payment
                at all.
              </p>
              <h2 className="font-IbarraRealNova text-d-6lg text-d text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Better Interest Rates
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Mortgage lenders earn their money from interest rates. Current rates are between 6%
                and 7%, depending on the term length and price. VA home lenders generally offer
                rates 0.5–1% lower than other loans. That means veterans can save tens of thousands
                of dollars on this reduction alone.
              </p>
              <h2 className="font-IbarraRealNova text-d-6lg text-d text-[#1A1A1A] font-[600] text-left mb-[16px]">
                No Need for Private Insurance
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Homeowners are typically required to pay for private mortgage insurance in case of
                default. VA home loans, however, don’t require private insurance, resulting in even
                more long-term savings.
              </p>
              <h2 className="font-IbarraRealNova text-d-6lg text-d text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Easier Credit Requirements
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Credit score trumps all other lending mechanisms these days, but military personnel
                don’t have to have a blemish-free credit report to qualify for a VA home loan — far
                from it.The bottom line differs from lender to lender, but veterans can expect lower
                credit requirements on average.
              </p>
              <h2 className="font-IbarraRealNova text-d-6lg text-d text-[#1A1A1A] font-[600] text-left mb-[16px]">
                More Cost Savings
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                With a VA home loan, you’ll avoid some overlooked mortgage fees and associated
                expenses. These include high closing costs, penalties for prepayment, refinancing
                costs, and more.
              </p>
              <h2 className="font-IbarraRealNova text-d-6lg text-d text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Variety of Loan Types
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                VA home loans aren’t a one-size-fits-all proposition. Veterans can line up financing
                for home purchases, interest rate reductions, and even investments in energy
                efficiency. And there are no hard-set limits on how much veterans can borrow.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                VA Home Loan Requirements
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                For those currently in the military, the main requirement is easy. If you’ve served
                at least 90 consecutive days in the Army, Navy, Air Force, Marines, Coast Guard,
                Space Force, or other qualified branches, you’re eligible for a VA home loan.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you’re a veteran, your eligibility will depend on when and how long you served.
                Any former service members who enlisted after August 1990 must fulfill one of the
                following requirements:
              </p>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li className="mb-2">At least 24 months of continuous service</li>
                <li className="py-2">At least 90 uninterrupted days of active duty</li>
                <li className="py-2">
                  90 days of service if you were discharged for difficulties or personnel reduction
                </li>
                <li className="py-2">
                  Less than 90 days of service if you were discharged for disabilities caused by
                  service
                </li>
              </ul>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Veterans who served before 1990 have slightly different requirements. These are
                based on the time they served and usually correspond to past conflicts (World War
                II, Vietnam, etc.). Check with your lender to learn more.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Learn More About VA Home Loans from The Selby Team
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you’re in a position to take advantage of VA home loan benefits, we can help.{" "}
                <ContactSlideoutLink text="Contact The Selby Team" /> to get started on finding your
                new home.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={blogUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
